#background-video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
}

.chart-container {
  background-color: rgba(255, 255, 255, 0.5);
  width: 600px;
  height: 600px;
  padding: 30px;
  margin: 30;
}
