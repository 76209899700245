#background-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
}

.paper {
  padding: 2;
  text-align: "center";
}
