@use "../../theme/base";

.modal {
  padding: 60px 20px 40px 20px;
  background-color: white;
  color: base.$primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px 0 0 0;
}

.slide-show {
  width: 100%;
  border: 0px solid silver;
  display: flex;
  flex-direction: column;
  background-color: base.$primary-light;
  border: 0px solid base.$primary-light;
  box-shadow: 5px;
  padding: 0px;
  margin: 0px;
  height: 100%;
}
.slide-show-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
}
.slide-show-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px;
  margin: 0px;
  width: 100%;
  border: 0px solid red;
  align-content: center;
}
.slide-show-content img {
  width: 100%;
  padding: 0px;
}

.slide-show-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  background-color: base.$primary-light;
}

/** override default style for MobileStepper **/
div.MuiMobileStepper-dot {
  background-color: base.$primary-dark !important;
}
div.MuiMobileStepper-dotActive {
  background-color: base.$secondary-light !important;
}
div.MuiMobileStepper-root {
  background-color: base.$primary-light !important;
}
