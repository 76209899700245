// _base.scss
// define any style used in a standard styling for component
// i.e. anything that can be appropriately supported by non-materialUI-specific styling.

$font-stack: Roboto, Helvetica, sans-serif;

// Color scheme

$primary-light: #f4f4f4;
$secondary-light: #80d8ff;
$info-light: #eee;
$warning-light: #eee;
$cancel-light: #eee;

$primary-dark: #333;
$secondary-dark: #eee;
$info-dark: #ee7722;
$warning-dark: #aa0088;
$cancel-dark: #eee;

body {
  font: 100% $font-stack;
  color: $primary-dark;
  font-size: 12pt;
}

.action-button {
  width: 90vw;
  display: "flex";
  justify-content: "center";
  align-items: center;
  text-align: center;
}

.primary-light {
  color: $primary-light;
}
.secondary-light {
  color: $secondary-light;
}
.info-light {
  color: $info-light;
}
.warning-light {
  color: $warning-light;
}
.cancel-light {
  color: $warning-light;
}
.primary-dark {
  color: $primary-dark;
}
.secondary-dark {
  color: $secondary-dark;
}
.info-dark {
  color: $info-dark;
}
.warning-dark {
  color: $warning-dark;
}
.cancel-dark {
  color: $warning-dark;
}

h1 {
  width: 100%;
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 100;
  text-align: left;
  margin: 20px 4px 20px 4px;
  flex-wrap: wrap;
}

h2 {
  width: 100%;
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 100;
  text-align: left;
  margin: 15px 4px 15px 4px;
  flex-wrap: wrap;
}

h3 {
  width: 100%;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 100;
  text-align: left;
  margin: 12px 4px 12px 4px;
  flex-wrap: wrap;
}

h3 li {
  margin-bottom: 10px;
  flex-wrap: wrap;
}

h4 {
  width: 100%;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 100;
  text-align: left;
  margin: 10px 4px 10px 4px;
  flex-wrap: wrap;
}
h5 {
  width: 100%;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 100;
  text-align: left;
  margin: 10px 4px 10px 4px;
  flex-wrap: wrap;
}

span {
  text-align: left;
  margin: 4px 6px 4px 6px;
}

.center {
  text-align: center;
}

.section-dark {
  background-color: $primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  padding: 100px 0 90px 0;
  margin: 0;
}

.section-light {
  background-color: $primary-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 100px;
  padding-bottom: 90px;
}
